import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'

import Count from '@/components/Count'
import Logo from '@/components/Logo'

import styles from '@/styles/Sidebar.module.css'

import { useSession } from '@/contexts/Session'

const navigations = [
    {
        id: 1,
        icon: 'pie-chart',
        label: 'Dashboard',
        link: '/dashboard',
    },
    {
        id: 2,
        icon: 'platform-user',
        label: 'Platform Users',
        link: '/platform-users',
        alert: false,
    },
    {
        id: 3,
        icon: 'briefcase',
        label: 'Disbursement',
        link: '/disbursement',
        alert: false,
    },
    {
        id: 4,
        icon: 'featured-content',
        label: 'Featured Content',
        link: '/featured-content',
        alert: false,
    },
    {
        id: 5,
        icon: 'manage-content',
        label: 'Manage Content',
        link: '/manage-content',
        alert: false,
    },
    {
        id: 6,
        icon: 'flag',
        label: 'Abuse Reports',
        link: '/abuse-report',
        alert: true,
    },
    {
        id: 7,
        icon: 'configuration',
        label: 'Configurations',
        link: '/configuration',
        alert: false,
    },
    {
        id: 8,
        icon: 'list',
        label: 'Tournament Types',
        link: '/tournament-types',
        alert: false,
    },
    {
        id: 9,
        icon: 'play',
        label: 'Tournament Games',
        link: '/tournament-games',
        alert: false,
    },
    {
        id: 10,
        icon: 'flag',
        label: 'Tournaments',
        link: '/tournaments',
        alert: false,
    },
    {
        id: 11,
        icon: 'platform-user',
        label: 'Tournament Participants',
        link: '/tournament-participants',
        alert: false,
    },
    {
        id: 12,
        icon: 'gift',
        label: 'Giveaways',
        link: '/giveaways',
        alert: false,
    },
    {
        id: 13,
        icon: 'platform-user',
        label: 'Giveaway Participants',
        link: '/giveaway-participants',
        alert: false,
    },
    {
        id: 14,
        icon: 'list',
        label: 'Dashboard Videos Types',
        link: '/dashboard-video-types',
        alert: false,
    },
    {
        id: 14,
        icon: 'play',
        label: 'Dashboard Videos',
        link: '/dashboard-videos',
        alert: false,
    },
]

const SideBar = (props) => {
    const { asPath, push } = useRouter()
    const { user, authToken, removeAuthToken } = useSession()

    const [sidebar, setSidebar] = useState(false)
    const [state, setstate] = useState({
        activeItem: null,
        navigations,
    })

    const activeNavigationClass = (link) => {
        let pathname = asPath
        if (pathname.lastIndexOf('/')) {
            pathname = pathname.slice(0, pathname.lastIndexOf('/'))
        }
        if (link && link.lastIndexOf('/')) {
            link = link.slice(0, link.lastIndexOf('/'))
        }
        return pathname === link ? styles.active : ''
    }

    const signOut = async () => {
        const signInUrl = `${process.env.NEXT_PUBLIC_API_URL}/auth/logout`

        const fetchOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken?.accessToken}`,
            },
        }
        await fetch(signInUrl, fetchOptions)
        removeAuthToken('accessToken', {
            path: '/',
            sameSite: true,
        })
        push('/')
    }

    return (
        <>
            <div>
                <div
                    onClick={() => setSidebar(!sidebar)}
                    className="fixed top-[14px] left-[33px] z-[91] hidden ipad:inline-block"
                >
                    <i className="icon-hamburger text-xl"></i>
                </div>
                <div
                    className={`${sidebar ? 'ipad:block' : 'ipad:hidden'} ${
                        styles.sidebar
                    }`}
                >
                    {/* ============== Logo =========== */}
                    <div className={styles.sidebarLogo}>
                        <Logo width={128} height={36} link="/" />
                        <div
                            onClick={() => setSidebar(!sidebar)}
                            className="absolute top-0 bottom-0 right-0 right-4 hidden items-center justify-center ipad:flex ipad:text-lg"
                        >
                            <i className="icon-cancel-circle"></i>
                        </div>
                    </div>
                    {/* ============== Sidebar Navigation Items ================*/}
                    <div
                        onClick={props.click}
                        className={`scrollable ${styles.sidebarNavigation}`}
                    >
                        <ul>
                            {state.navigations.map((data, index) => (
                                <li
                                    key={index}
                                    className={`${activeNavigationClass(
                                        data.link
                                    )} item-center flex`}
                                    onClick={() => {
                                        setstate({
                                            ...state,
                                            activeItem:
                                                state.navigations[index],
                                        })
                                        setSidebar(false)
                                    }}
                                >
                                    <i className={`icon-${data.icon}`} />
                                    <span>{data.label}</span>
                                    <Link href={data.link}>
                                        <a className="absolute top-0 left-0 right-0 h-full w-full text-common-white"></a>
                                    </Link>
                                    {!!data?.alert && (
                                        <Count uri="reported-stream/count" />
                                    )}
                                    <span
                                        className={`icon-chevron-right absolute top-1.5 right-2 text-[22px] leading-none text-common-white ${styles.activeArrow}`}
                                    ></span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.userSection}>
                        <div
                            onClick={() => signOut()}
                            className={styles.signOut}
                        >
                            <i className="icon-log-out"></i>
                            <span>Sign Out</span>
                        </div>
                        {user?.email && (
                            <div className={styles.userLoggedIn}>
                                <div className="relative flex">
                                    <div className="w-full">
                                        <h6
                                            className={`mb-0 font-normal text-common-white`}
                                        >
                                            {user?.displayName || ''}
                                        </h6>
                                        <p
                                            className={`overflow-hidden text-ellipsis whitespace-nowrap text-xs text-interface-400`}
                                        >
                                            {user?.email || ''}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SideBar
